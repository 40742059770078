import moment from 'moment';
import formatCurrency from '@nc-aftermarket/shared/utils/formatCurrency';

export { formatCurrency };

export function formatDate(timestamp) {
  return new Date(timestamp).toLocaleString();
}

export function formatDateFromNow(timestamp) {
  const date = new Date(timestamp);

  // If within the last few hours return "recently"
  if (Date.now() - date < 1000 * 60 * 60 * 3) {
    return 'recently';
  }

  return moment(date).fromNow();
}

export function formatTime(timestamp) {
  return new Date(timestamp).toLocaleTimeString();
}

export function formatNumber(input) {
  return input.toLocaleString();
}
