import _upperFirst from 'lodash/upperFirst';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Link } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import DataTable from '../components/DataTable';
import { formatCurrency, formatDate, formatNumber } from '../lib/formatters';
import useTableUtils from '../lib/useTableUtils';
import useWaitCursor from '../lib/useWaitCursor';

const columns = [
  {
    key: 'username',
    label: 'Username',
    Cell: row => (
      <Link component={RouterLink} to={`/users/${row.id}`}>
        {row.username}
      </Link>
    ),
  },
  {
    key: 'bidCount',
    label: 'Bids',
    Cell: row => formatNumber(row.bidCount),
  },
  {
    key: 'expiredNonRunnerupInvoices',
    label: 'Expired Inv',
    Cell: row =>
      row.invoiceCountExpiredNonRunnerup > 0
        ? `${row.invoiceCountExpiredNonRunnerup} / ${
            row.invoiceCount
          } (${Math.floor(
            (row.invoiceCountExpiredNonRunnerup / row.invoiceCount) * 100,
          )}%)`
        : '0',
  },
  {
    key: 'expiredNonRunnerupInvoicesLast30Days',
    label: 'Expired Inv (30 days)',
    Cell: row => row.invoiceCountExpiredNonRunnerupLast30Days,
  },
  {
    key: 'totalRevenue',
    label: 'Total Rev',
    Cell: row => formatCurrency(row.totalRevenue),
  },
  {
    key: 'status',
    label: 'Status',
    Cell: row => _upperFirst(row.status),
  },
  {
    key: 'lastBannedDate',
    label: 'Last Banned',
    Cell: row => (row.lastBannedDate ? formatDate(row.lastBannedDate) : ''),
  },
];

const filterDefs = [
  {
    facets: [
      {
        label: 'Any',
        value: '',
      },
      {
        label: 'Banned',
        value: 'banned',
      },
      {
        label: 'Unverified',
        value: 'unverified',
      },
      {
        label: 'Verified',
        value: 'verified',
      },
    ],
    formatter: _upperFirst,
    label: 'Status',
    key: 'status',
    type: 'facet',
  },
];

export default function UserTable() {
  const { filter, setFilter, setState, state } = useTableUtils();
  const {
    order = 'desc',
    orderBy = 'bidCount',
    page = 1,
    pageSize = 100,
  } = state;
  const { data, error, loading } = useQuery(
    gql`
      query UserTablePage(
        $filter: UserFilter
        $page: Int
        $pageSize: Int
        $sort: [UserSort]
      ) {
        users(filter: $filter, page: $page, pageSize: $pageSize, sort: $sort) {
          items {
            bidCount
            createdDate
            id
            name
            status
            username
            webhookURL
            invoiceCount
            invoiceCountExpiredNonRunnerup: invoiceCount(
              type: expiredNonRunnerup
            )
            invoiceCountExpiredNonRunnerupLast30Days: invoiceCount(
              type: expiredNonRunnerupLast30Days
            )
            totalRevenue
            lastBannedDate
          }
          page
          pageCount
          pageSize
          query
          total
        }
      }
    `,
    {
      variables: {
        page,
        pageSize,
        filter: {
          keywords: filter.query,
          role: filter.role,
          status: filter.status,
        },
        sort: [
          {
            column: orderBy,
            direction: order,
          },
        ],
      },
    },
  );

  useWaitCursor(loading);

  if (error) {
    return (
      <Alert className="m-4" severity="error" variant="filled">
        {error.message}
      </Alert>
    );
  }

  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <DataTable
        data={data?.users}
        columnDefs={columns}
        filter={filter}
        filterDefs={filterDefs}
        loading={loading}
        onSetPage={page => setState({ page })}
        onSetPageSize={pageSize => setState({ page: 1, pageSize })}
        onSetFilter={setFilter}
        onSort={(order, orderBy) => setState({ order, orderBy })}
        order={order}
        orderBy={orderBy}
        page={page}
        pageSize={pageSize}
      />
    </>
  );
}
