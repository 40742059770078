import { useQuery, gql, useMutation } from '@apollo/client';
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
  Select,
} from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import useWaitCursor from '../lib/useWaitCursor';
import isDateInputSupported from '../lib/isDateInputSupported';
import { useState } from 'react';
import { formatCurrency } from '../lib/formatters';

const metrics = [
  ['saleRevenueSold', 'Sold Revenue'],
  ['bidCountTotal', 'Total Bids'],
  ['saleCountBidded', 'Total Auctions with Bids'],
];

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const currentMonth = new Date().getMonth();
const currentYear = new Date().getFullYear();

const dateRangeShortcuts = {
  thisYear: {
    label: 'This Year',
    from: `${currentYear}-01-01`,
    to: `${currentYear + 1}-01-01`,
  },
  lastYear: {
    label: 'Last Year',
    from: `${currentYear - 1}-01-01`,
    to: `${currentYear}-01-01`,
  },
  allTime: {
    label: 'All Time',
    from: '',
    to: '',
  },
};

function mod(n, d) {
  return ((n % d) + d) % d;
}

for (let i = 0; i < 12; i++) {
  const cursor = currentMonth - i; // i = 0, currentMonth = 11, cursor = 11
  const fromYear = cursor < 0 ? currentYear - 1 : currentYear; // fromYear: 2022
  const fromMonth = mod(cursor, 12) + 1; // fromMonth: 12
  const toYear =
    cursor < -1
      ? currentYear - 1
      : cursor === 11
      ? currentYear + 1
      : currentYear; // toYear: 2022
  const toMonth = mod(cursor + 1, 12) + 1; // toMonth: 1
  dateRangeShortcuts[i] = {
    label: `${monthNames[mod(cursor, 12)]} ${fromYear}`,
    from: `${fromYear}-${('0' + fromMonth).slice(-2)}-01`,
    to: `${toYear}-${('0' + toMonth).slice(-2)}-01`,
  };
}

const defaultDateRange = dateRangeShortcuts[0];

export default function Dashboard() {
  const [fromDate, setFromDate] = useState(defaultDateRange.from);
  const [toDate, setToDate] = useState(defaultDateRange.to);
  const { data, error, loading } = useQuery(
    gql`
      query ConsoleDashboard($filter: SaleFilter) {
        sales(filter: $filter) {
          stats {
            bidCountTotal
            saleCountBidded
            saleRevenueSold
          }
        }
      }
    `,
    {
      variables: {
        filter: {
          endDate:
            fromDate || toDate
              ? {
                  min: fromDate
                    ? new Date(fromDate).getTime() / 1000
                    : undefined,
                  max: toDate ? new Date(toDate).getTime() / 1000 : undefined,
                }
              : undefined,
        },
      },
    },
  );

  const [clearQueryCache, clearQueryCacheQuery] = useMutation(gql`
    mutation ClearQueryCache {
      clearQueryCache {
        success
        value
      }
    }
  `);

  const onClearQueryCache = async () => {
    try {
      if (window.confirm('Are you sure you want to clear the query cache?')) {
        await clearQueryCache();
        window.alert('Success');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [extendAuctions, extendAuctionsQuery] = useMutation(gql`
    mutation ExtendAuctions {
      extendAuctions {
        success
      }
    }
  `);

  const onExtendAuctions = async () => {
    try {
      if (
        window.prompt(
          'This adds a 1-hour extension to all auctions ending within the next hour. It is meant to be used in case of a DDoS attack or other unexpected site issues when auctions are ending. This cannot be undone. Type "EXTEND AUCTIONS BY ONE HOUR" to confirm.',
        ) === 'EXTEND AUCTIONS BY ONE HOUR'
      ) {
        await extendAuctions();
        window.alert('Success');
      } else {
        window.alert(
          'Invalid confirmation message received. Auctions were NOT extended!',
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [generateReport, generateReportQuery] = useMutation(gql`
    mutation GenerateReport($reportKey: String!) {
      generateReport(input: { reportKey: $reportKey }) {
        url
        success
      }
    }
  `);

  const onClickReport = async reportKey => {
    try {
      const result = await generateReport({
        variables: {
          reportKey,
        },
      });
      if (!result.data.generateReport.success) {
        window.alert('Failed to generate report');
      } else {
        window.location.href = result.data.generateReport.url;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useWaitCursor(
    loading ||
      clearQueryCacheQuery.loading ||
      generateReportQuery.loading ||
      extendAuctionsQuery.loading,
  );

  const selectedDateRangeOption =
    Object.keys(dateRangeShortcuts).find(
      key =>
        dateRangeShortcuts[key].to === toDate &&
        dateRangeShortcuts[key].from === fromDate,
    ) || 'custom';

  return (
    <>
      {error && (
        <Alert className="m-4" severity="error" variant="filled">
          {error.message}
        </Alert>
      )}
      <div className="p-2 sm:p-4 lg:p-6">
        <div className="pt-2 pb-4 sm:pt-0 sm:flex overflow-hidden sm:overflow-visible">
          <FormControl className="flex flex-auto" variant="outlined">
            <InputLabel htmlFor="date-range-select">Time Period</InputLabel>
            <Select
              className="mb-4 block sm:inline-flex sm:mr-4 flex-auto"
              inputProps={{
                id: 'date-range-select',
                title: 'Select one',
              }}
              label="Time Period"
              native
              onChange={event => {
                const key = event.target.value;
                if (dateRangeShortcuts[key]) {
                  setFromDate(dateRangeShortcuts[key].from);
                  setToDate(dateRangeShortcuts[key].to);
                } else {
                  setFromDate('');
                  setToDate('');
                }
              }}
              size="small"
              value={selectedDateRangeOption}>
              {selectedDateRangeOption === 'custom' && (
                <option value="custom" disabled>
                  Custom
                </option>
              )}
              {Object.keys(dateRangeShortcuts).map(key => (
                <option key={key} value={key}>
                  {dateRangeShortcuts[key].label}
                </option>
              ))}
            </Select>
          </FormControl>
          <div className="flex-auto sm:mr-4 mb-4">
            <TextField
              className="mr-4"
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputProps={{
                pattern: 'd{4}-d{2}-d{2}',
                title: 'From Date',
              }}
              label={`From${
                !isDateInputSupported() ? ' (YYYY-MM-DD)' : ''
              } (auction end date, 00:00)`}
              onChange={event => {
                setFromDate(
                  /\d{4}-\d{2}-\d{2}/.test(event.target.value)
                    ? event.target.value
                    : null,
                );
              }}
              type="date"
              variant="outlined"
              value={fromDate}
            />
          </div>
          <div className="flex-auto">
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputProps={{
                pattern: 'd{4}-d{2}-d{2}',
                title: 'To Date',
              }}
              label={`To${
                !isDateInputSupported() ? ' (YYYY-MM-DD)' : ''
              } (auction end date, 00:00)`}
              onChange={event => {
                setToDate(
                  /\d{4}-\d{2}-\d{2}/.test(event.target.value)
                    ? event.target.value
                    : null,
                );
              }}
              type="date"
              variant="outlined"
              value={toDate}
            />
          </div>
        </div>
        <Grid alignItems="stretch" container spacing={2}>
          {metrics.map(metric => (
            <Grid key={metric[0]} item xs={12} md={6} lg={3}>
              <div className="relative h-48">
                {loading ? (
                  <Skeleton
                    className="absolute inset-0 h-full rounded"
                    variant="rect"
                  />
                ) : (
                  <Paper className="relative text-center p-4 h-full flex flex-col justify-center items-center">
                    <Typography
                      variant="h5"
                      display="block"
                      className="font-semibold">
                      {data?.sales.stats[metric[0]]
                        ? metric[1].toLowerCase().indexOf('revenue') !== -1
                          ? formatCurrency(data.sales.stats[metric[0]])
                          : data.sales.stats[metric[0]].toLocaleString()
                        : '—'}
                    </Typography>
                    <Typography
                      variant="overline"
                      display="block"
                      className="opacity-75">
                      {metric[1]}
                    </Typography>
                  </Paper>
                )}
              </div>
            </Grid>
          ))}
        </Grid>
        <div className="mt-16">
          <Typography variant="overline">Reports</Typography>
          <Divider className="mb-4" />
          <div className="flex gap-2">
            <Button
              onClick={() => onClickReport('bannedusers')}
              color="default"
              variant="contained">
              Banned Users
            </Button>
          </div>
        </div>
        <div className="mt-16">
          <Typography variant="overline">Danger Zone</Typography>
          <Divider className="mb-4" />
          <div className="flex gap-2">
            <Button
              onClick={onExtendAuctions}
              color="default"
              variant="contained">
              Extend Auctions
            </Button>
            <Button
              onClick={onClearQueryCache}
              color="default"
              variant="contained">
              Clear Query Cache
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
