import cx from 'classnames';
import _startCase from 'lodash/startCase';
import _upperFirst from 'lodash/upperFirst';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import EditIcon from '@material-ui/icons/Edit';
import { Alert } from '@material-ui/lab';
import DataTable from '../components/DataTable';
import { formatDate } from '../lib/formatters';
import useTableUtils from '../lib/useTableUtils';
import useWaitCursor from '../lib/useWaitCursor';
import { useState } from 'react';

const columns = [
  {
    isSortable: false,
    key: 'idDatabase',
    label: 'ID',
  },
  {
    isSortable: false,
    key: 'parentId',
    label: 'PID',
    Cell: row => row.parent?.idDatabase,
  },
  {
    isSortable: false,
    key: 'body',
    label: 'Comment',
    Cell: row => (
      <div
        className={cx(
          'w-60 whitespace-pre-wrap line-clamp-3',
          row.status === 'trash' || row.status === 'spam' ? 'line-through' : '',
        )}
        title={row.body}>
        {row.body}
      </div>
    ),
  },
  {
    key: 'flagCount',
    label: 'Flags',
  },
  {
    key: 'status',
    label: 'Status',
    Cell: row => {
      return _startCase(row.status);
    },
  },
  {
    accessor: row => row.sale.product.name,
    isSortable: false,
    key: 'sale',
    label: 'Sale',
    Cell: row => (
      <Link component={RouterLink} to={`/sales/${row.sale.id}`}>
        {row.sale.product.name}
      </Link>
    ),
  },
  {
    accessor: row => row.user.username,
    isSortable: false,
    key: 'username',
    label: 'User',
    Cell: row => (
      <Link component={RouterLink} to={`/users/${row.user.id}`}>
        {row.user.username}
      </Link>
    ),
  },
  {
    key: 'createdDate',
    label: 'Date',
    Cell: row => formatDate(row.createdDate),
  },
  {
    key: 'actions',
    isSortable: false,
    label: 'Actions',
    Cell: row => (
      <Tooltip title="Edit">
        <IconButton
          aria-label="edit bid"
          component={RouterLink}
          color="inherit"
          edge="start"
          to={`/comments/${row.idDatabase}`}>
          <EditIcon className="w-5 h-5" />
        </IconButton>
      </Tooltip>
    ),
  },
];

const filterDefs = [
  {
    facets: [
      {
        label: 'Any',
        value: '',
      },
      {
        label: 'Pending',
        value: 'pending',
      },
      {
        label: 'Approved',
        value: 'approved',
      },
      {
        label: 'Spam',
        value: 'spam',
      },
      {
        label: 'Trash',
        value: 'trash',
      },
    ],
    formatter: _upperFirst,
    label: 'Status',
    key: 'status',
    type: 'facet',
  },
];

export default function Comments() {
  const params = useParams();
  const history = useHistory();
  const { filter, setFilter, setState, state } = useTableUtils();
  const [cursor, setCursor] = useState(null);
  const [statusInput, setStatusInput] = useState('');
  const [note, setNote] = useState('');

  const {
    order = 'desc',
    orderBy = 'status',
    page = 1,
    pageSize = 100,
  } = state;

  const { data, error, loading } = useQuery(
    gql`
      query CommentsPage(
        $filter: CommentFilter
        $page: Int
        $pageSize: Int
        $sort: [CommentSort]
      ) {
        comments(
          filter: $filter
          page: $page
          pageSize: $pageSize
          sort: $sort
        ) {
          items {
            body
            createdDate
            flagCount
            id
            idDatabase
            status
            parent {
              idDatabase
            }
            sale {
              id
              product {
                name
              }
            }
            user {
              id
              username
            }
          }
          page
          pageCount
          pageSize
          total
        }
      }
    `,
    {
      variables: {
        page,
        pageSize,
        filter: {
          keywords: filter.query,
          status: filter.status,
        },
        sort: [
          {
            column: orderBy,
            direction: order,
          },
        ],
      },
    },
  );

  useEffect(() => {
    if (data && params.id) {
      const newCursor = data.comments.items.find(
        item => item.idDatabase === window.parseInt(params.id),
      );
      setCursor(newCursor);
      setStatusInput(newCursor.status);
    }
  }, [data, params.id]);

  const [updateComment, updateCommentQuery] = useMutation(
    gql`
      mutation UpdateComment($id: String!, $status: COMMENT_STATUS) {
        updateComment(input: { id: $id, status: $status }) {
          comment {
            id
            body
            status
          }
          success
        }
      }
    `,
    {
      refetchQueries: ['NavPendingCommentsCount'],
    },
  );

  const [attachAdminNote, attachAdminNoteQuery] = useMutation(gql`
    mutation AttachAdminNote($note: String!, $comment: String!) {
      attachAdminNote(input: { note: $note, comment: $comment }) {
        success
      }
    }
  `);

  useWaitCursor(
    loading || updateCommentQuery.loading || attachAdminNoteQuery.loading,
  );

  const onSubmit = async event => {
    event.preventDefault();
    try {
      await updateComment({
        variables: {
          id: cursor.id,
          status: statusInput,
        },
      });
      await attachAdminNote({
        variables: { comment: cursor.id, note },
      });
      setNote('');
      history.push('/comments');
    } catch (error) {
      window.alert('Failed to edit comment');
    }
  };

  if (error) {
    return (
      <Alert className="m-4" severity="error" variant="filled">
        {error.message}
      </Alert>
    );
  }

  return (
    <>
      <Helmet>
        <title>Comments</title>
      </Helmet>
      <Drawer
        anchor="right"
        open={!!params.id}
        onClose={() => history.push('/comments')}>
        <div className="flex p-3">
          <IconButton onClick={() => history.push('/comments')}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <form className="p-8 max-w-screen-sm w-screen" onSubmit={onSubmit}>
          <Typography gutterBottom variant="h5">
            Edit comment {cursor?.idDatabase}
          </Typography>
          <div className="my-6">
            <FormControl className="w-full" variant="outlined">
              <InputLabel htmlFor="status">Select a status</InputLabel>
              <Select
                inputProps={{
                  id: 'status',
                }}
                native
                value={statusInput}
                label="Select a status"
                onChange={event => setStatusInput(event.target.value)}>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="spam">Spam</option>
                <option value="trash">Trash</option>
              </Select>
            </FormControl>
          </div>
          <div className="my-4">
            <TextField
              fullWidth
              label="Note"
              multiline
              onChange={event => setNote(event.target.value)}
              required
              value={note}
              variant="outlined"
            />
          </div>
          <div className="flex justify-end">
            <Button color="primary" type="submit" variant="contained">
              Save
            </Button>
          </div>
        </form>
      </Drawer>
      <DataTable
        data={data?.comments}
        columnDefs={columns}
        filter={filter}
        filterDefs={filterDefs}
        loading={loading}
        isRowSelected={row => row.status === 'pending'}
        onSetPage={page => setState({ page })}
        onSetPageSize={pageSize => setState({ page: 1, pageSize })}
        onSetFilter={setFilter}
        onSort={(order, orderBy) => setState({ order, orderBy })}
        order={order}
        orderBy={orderBy}
        page={page}
        pageSize={pageSize}
        searchHelperText="Domain, username, or keywords…"
      />
    </>
  );
}
